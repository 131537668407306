import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import pkg from '../package.json';
import { hmrBootstrap } from './hmr';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

/* Sentry Initialization - captures both error and performance data
@sentry/angular will report any uncaught exceptions triggered by your application.
*/
if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry, // Sentry project identifier
    release: `@nexus/${pkg.name}@${pkg.version}`, // Unique identifier for this release
    integrations: [
      Sentry.browserTracingIntegration(), // Enables performance monitoring
      // Sentry.replayIntegration(), // Enables session replay
      Sentry.captureConsoleIntegration() // Captures console logs
    ],
    tracesSampleRate: 1.0, // Capture 100% of transactions for performance monitoring
    sampleRate: 0.5, // Capture 50% of errors
    environment: environment.environment, // Sets the environment (e.g., 'production', 'staging')
    // replaysSessionSampleRate: 0.3, // Capture 30% of sessions for replay
    // replaysOnErrorSampleRate: 0.5, // Capture 50% of sessions with errors for replay
    debug: false // Enable debug mode for verbose logging
  });
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  // eslint-disable-next-line
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  }
} else {
  bootstrap();
}
